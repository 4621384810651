var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-container',{staticClass:"py-0 mb-4",attrs:{"fluid":"","tag":"section"}},[_c('v-row',{staticClass:"secondary px-4 br-8 pt-0 pb-2"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search staff members","single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search_term),callback:function ($$v) {_vm.search_term=$$v},expression:"search_term"}})],1),_c('v-col',{staticClass:"d-flex justify-end align-end",attrs:{"cols":"12","md":"8"}},[_c('v-btn',{staticClass:"mr-0",attrs:{"color":"yellow","rounded":""},on:{"click":_vm.handle_add_staff}},[_vm._v(" + Add a staff member ")])],1)],1)],1),_c('v-data-table',{key:_vm.staff_datatable_key,staticClass:"br-8",attrs:{"headers":_vm.headers,"items":_vm.staff,"search":_vm.search_term,"items-per-page":10,"item-class":_vm.table_row_class,"item-key":"id"},scopedSlots:_vm._u([{key:"item.last_seen_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.moment(item.last_seen_at).format("DD/MM/YYYY HH:mm"))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("DD/MM/YYYY HH:mm"))+" ")])]}},{key:"header.is_active",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2"},on),[_vm._v("mdi-information-outline")]),_c('span',[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(" Manage your active staff members easily by switching the toggle on and off ")])])]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-switch',{attrs:{"color":"primary"},on:{"change":function($event){return _vm.toggle_enabled(item)}},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-space-around"},[_c('v-tooltip',{attrs:{"bottom":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex align-center justify-center cursor-pointer mr-4",class:{
                'mr-8': _vm.$vuetify.breakpoint.smAndDown,
              },on:{"click":function($event){return _vm.handle_edit(item)}}},on),[_c('v-icon',{attrs:{"small":"","color":"dark-grey"},domProps:{"textContent":_vm._s('$edit')}})],1)]}}],null,true)},[_vm._v(" Edit ")])],1)]}},{key:"item.role",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.startCase(item.role))+" ")])]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v("You don't have any staff members, try creating one")])]},proxy:true}])}),_c('StaffForm',{attrs:{"visible":_vm.show_staff_form,"selected_staff":_vm.selected_staff,"edit_mode":_vm.editing_staff},on:{"close":function($event){_vm.show_staff_form = false}}}),_c('StaffAccountDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }