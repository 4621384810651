<template>
  <v-dialog
    v-model="show"
    scrollable
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="600px"
  >
    <v-card
      class="action-modal"
      :class="{ 'br-16': $vuetify.breakpoint.mdAndUp }"
    >
      <v-card-title
        class="px-4 py-3 primary font-weight-bold secondary--text text-center"
      >
        <span>Register a Staff Account</span>
      </v-card-title>
      <v-card-subtitle class="pt-5 mt-4 ml-3">
        <span class="d-block mb-2 text-subtitle-2">
          To add and manage staff members, you must first create a staff
          account.
        </span>
        <span class="text-subtitle-2">
          It will be used to log your staff into their dashboards.
        </span>
      </v-card-subtitle>
      <v-card-text class="pt-2">
        <v-container class="action-modal-content">
          <v-form v-model="form_valid" ref="form">
            <v-row>
              <v-col cols="12">
                <p
                  class="
                    mb-0
                    headline
                    input-label
                    font-weight-bold
                    dark-grey--text
                  "
                >
                  Email Address <span class="red--text">*</span>
                </p>
                <v-text-field
                  v-model="staff_account.email"
                  type="text"
                  dense
                  single-line
                  maxlength="100"
                  :rules="email_rules"
                  required
                />
              </v-col>
              <v-col cols="12">
                <p
                  class="
                    mb-0
                    headline
                    input-label
                    font-weight-bold
                    dark-grey--text
                  "
                >
                  Password <span class="red--text">*</span>
                </p>
                <v-text-field
                  v-model="staff_account.password"
                  dense
                  single-line
                  maxlength="50"
                  required
                  :type="show_password ? 'text' : 'password'"
                  :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="password_rules"
                  @click:append="show_password = !show_password"
                />
              </v-col>
              <v-col cols="12">
                <p
                  class="
                    mb-0
                    headline
                    input-label
                    font-weight-bold
                    dark-grey--text
                  "
                >
                  Re-enter Password <span class="red--text">*</span>
                </p>
                <v-text-field
                  v-model="staff_account.confirm_password"
                  dense
                  single-line
                  :type="show_password ? 'text' : 'password'"
                  maxlength="50"
                  :rules="confirm_password_rules"
                  required
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="black" text rounded @click="$router.go(-1)">
          Not now
        </v-btn>
        <v-btn
          elevation="0"
          color="primary"
          rounded
          @click="submit"
          :disabled="!form_valid || is_loading"
        >
          <v-progress-circular
            v-if="is_loading"
            :size="20"
            indeterminate
            color="white"
          />
          <span v-else>{{ "Submit" }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";

import { register_staff_user } from "@/requests";
import {
  field_req,
  valid_email,
  valid_password,
  passwords_match,
} from "@/utils/form_val_rules";
import { db } from "@/db";

export default {
  name: "StaffAccountDialog",
  data() {
    return {
      form_valid: false,
      is_loading: false,
      show_password: false,
      show: false,
      email_rules: [field_req, valid_email],
      password_rules: [field_req, valid_password],
      confirm_password_rules: [
        field_req,
        (v) => passwords_match(v, this.staff_account.password),
      ],
      staff_account: {
        email: null,
        password: null,
        confirm_password: null,
      },
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop_id"]),
  },
  methods: {
    async submit() {
      try {
        this.is_loading = true;
        await register_staff_user(
          this.staff_account.email,
          this.staff_account.password,
          this.staff_account.confirm_password
        );
        await this.check_staff_uid_exists();
      } catch (e) {
        console.error("Failed to create a staff account ", e);
      } finally {
        this.is_loading = false;
      }
    },
    async check_staff_uid_exists() {
      const auth_doc = await db
        .collection("shops")
        .doc(this.shop_id)
        .collection("admin_data")
        .doc("authentication")
        .get();

      const staff_uid = auth_doc.exists ? auth_doc.data().staff_uid : null;

      this.show = staff_uid ? false : true;
    },
  },
  async mounted() {
    await this.check_staff_uid_exists();
  },
};
</script>
