<template>
  <v-dialog
    v-model="show"
    scrollable
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="600px"
    @keydown.esc="
      show = false;
      clear();
    "
  >
    <v-card
      class="action-modal"
      :class="{ 'br-16': $vuetify.breakpoint.mdAndUp }"
    >
      <v-card-title
        class="px-4 py-3 primary font-weight-bold secondary--text text-center"
      >
        <span>{{
          edit_mode ? "Edit Staff Member" : "Add New Staff Member"
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-container class="action-modal-content">
          <v-form v-model="form_valid" ref="form">
            <v-row>
              <v-col cols="12">
                <p
                  class="
                    mb-0
                    headline
                    input-label
                    font-weight-bold
                    dark-grey--text
                  "
                >
                  Staff Member Name <span class="red--text">*</span>
                </p>
                <v-text-field
                  type="text"
                  v-model="staff.name"
                  dense
                  single-line
                  maxlength="40"
                  counter="40"
                  :rules="rules_name"
                  required
                />
                <p
                  class="
                    mb-0
                    headline
                    input-label
                    font-weight-bold
                    dark-grey--text
                  "
                >
                  Role <span class="red--text">*</span>
                </p>
                <v-select v-model="staff.role" :items="selectable_roles" />
              </v-col>
              <v-col cols="12" v-if="!edit_mode">
                <p
                  class="
                    mb-0
                    headline
                    input-label
                    font-weight-bold
                    dark-grey--text
                  "
                >
                  Access PIN <span class="red--text">*</span>
                </p>
                <v-text-field
                  v-model="staff.pin"
                  dense
                  single-line
                  counter="4"
                  :rules="rules_pin"
                  required
                  type="text"
                  maxlength="4"
                  @keydown="handle_pin_input($event)"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="black" text rounded @click="(show = false), clear()">
          Close
        </v-btn>
        <v-btn
          elevation="0"
          color="primary"
          rounded
          @click="submit"
          :disabled="!form_valid || is_loading"
        >
          <v-progress-circular
            v-if="is_loading"
            :size="20"
            indeterminate
            color="white"
          />
          <span v-else>{{ edit_mode ? "Save" : "Add staff member" }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { mapActions, mapState } from "vuex";

import {
  field_req,
  is_number,
  txt_min_2_char,
  length_min_max,
} from "@/utils/form_val_rules";

export default {
  name: "StaffForm",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selected_staff: {
      type: Object,
      default: null,
    },
    edit_mode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form_valid: false,
      rules_name: [field_req, txt_min_2_char],
      rules_pin: [field_req, is_number, (v) => length_min_max(v, 4, 4)],
      empty_staff: {
        name: null,
        pin: null,
        role: "staff",
      },
      staff: {
        name: null,
        pin: null,
        role: "staff",
      },
    };
  },
  computed: {
    ...mapState("StaffStore", ["is_loading", "logged_in_staff"]),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    selectable_roles: function () {
      const basic_roles = [
        { text: "Staff", value: "staff" },
        { text: "Delivery Staff", value: "delivery_staff" },
        { text: "Manager", value: "manager" },
      ];
      const admin_role = { text: "Admin", value: "admin" };

      return this.logged_in_staff && this.logged_in_staff.role != "admin"
        ? basic_roles
        : [...basic_roles, admin_role];
    },
  },
  watch: {
    selected_staff(staff) {
      this.staff = staff ? cloneDeep(staff) : this.empty_staff;
    },
  },
  methods: {
    ...mapActions("StaffStore", [
      "select_staff",
      "update_staff_member",
      "create_staff_member",
    ]),
    async submit() {
      try {
        if (this.edit_mode) {
          const payload = {
            staff_id: this.staff.id,
            params: this.staff,
          };
          await this.update_staff_member(payload);
        } else {
          await this.create_staff_member(this.staff);
        }
        this.show = false;
        this.clear();
      } catch (e) {
        console.error(e);
      }
    },
    clear() {
      this.select_staff(null);
      this.staff = this.empty_staff;
      this.$refs.form.resetValidation();
    },
    handle_pin_input(event) {
      if (["Home", "End"].includes(event.key) || event.key.startsWith("Arrow"))
        return true;
      let char = String.fromCharCode(event.keyCode);
      if (/[\d\b]+/.test(char)) return true;
      else event.preventDefault();
    },
  },
};
</script>
