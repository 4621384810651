<template>
  <v-container fluid tag="section">
    <v-container fluid tag="section" class="py-0 mb-4">
      <v-row class="secondary px-4 br-8 pt-0 pb-2">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search_term"
            append-icon="mdi-magnify"
            label="Search staff members"
            single-line
            clearable
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="8" class="d-flex justify-end align-end">
          <v-btn class="mr-0" color="yellow" rounded @click="handle_add_staff">
            + Add a staff member
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="staff"
      :search="search_term"
      :items-per-page="10"
      :item-class="table_row_class"
      :key="staff_datatable_key"
      item-key="id"
      class="br-8"
    >
      <template v-slot:item.last_seen_at="{ item }">
        <span>
          {{ moment(item.last_seen_at).format("DD/MM/YYYY HH:mm") }}
        </span>
      </template>

      <template v-slot:item.created_at="{ item }">
        <span>
          {{ moment(item.created_at).format("DD/MM/YYYY HH:mm") }}
        </span>
      </template>

      <template v-slot:header.is_active="{ header }">
        <v-tooltip bottom content-class="custom-tooltip">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="mr-2">mdi-information-outline</v-icon>
            <span>{{ header.text }}</span>
          </template>
          <span>
            Manage your active staff members easily by switching the toggle on
            and off
          </span>
        </v-tooltip>
      </template>

      <template v-slot:item.is_active="{ item }">
        <div class="d-flex justify-center">
          <v-switch
            color="primary"
            v-model="item.is_active"
            @change="toggle_enabled(item)"
          ></v-switch>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex flex-row justify-space-around">
          <v-tooltip bottom content-class="custom-tooltip">
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                class="d-flex align-center justify-center cursor-pointer mr-4"
                :class="{
                  'mr-8': $vuetify.breakpoint.smAndDown,
                }"
                @click="handle_edit(item)"
              >
                <v-icon small color="dark-grey" v-text="'$edit'" />
              </div>
            </template>
            Edit
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.role="{ item }">
        <span> {{ startCase(item.role) }} </span>
      </template>

      <template v-slot:no-data>
        <p>You don't have any staff members, try creating one</p>
      </template>
    </v-data-table>
    <StaffForm
      :visible="show_staff_form"
      :selected_staff="selected_staff"
      :edit_mode="editing_staff"
      @close="show_staff_form = false"
    />
    <StaffAccountDialog />
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import { startCase } from "lodash";

import StaffForm from "../forms/StaffForm";
import StaffAccountDialog from "@/views/dashboard/components/StaffAccountDialog";

export default {
  name: "Staff",
  components: {
    StaffForm,
    StaffAccountDialog,
  },
  data() {
    return {
      moment,
      startCase,
      search_term: "",
      show_staff_form: false,
      editing_staff: false,
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Created",
          align: "center",
          value: "created_at",
        },
        {
          text: "Last seen",
          align: "center",
          value: "last_seen_at",
        },
        { text: "Role", value: "role", align: "center" },
        { text: "Active", value: "is_active", align: "center" },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop", "shop_id", "product_categories"]),
    ...mapState("StaffStore", [
      "staff",
      "selected_staff",
      "staff_datatable_key",
    ]),
  },
  created() {
    this.get_staff_members();
  },
  methods: {
    ...mapActions("StaffStore", [
      "get_staff_members",
      "update_staff_member",
      "select_staff",
    ]),
    table_row_class(item) {
      if (!item.is_active) return "not-enabled";
    },
    async update_staff_field(staff, field, value) {
      let payload = {
        staff_id: staff.id,
        params: {},
      };
      payload.params[field] = value;
      try {
        await this.update_staff_member(payload);
      } catch (err) {
        console.error("Failed to update staff", err);
      }
    },
    toggle_enabled(staff) {
      this.update_staff_field(staff, "is_active", staff.is_active);
    },
    handle_edit(staff) {
      this.select_staff(staff);
      this.editing_staff = true;
      this.show_staff_form = true;
    },
    handle_add_staff() {
      this.editing_staff = false;
      this.show_staff_form = true;
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
::v-deep .not-enabled {
  color: #c9c9c9;
}
</style>
